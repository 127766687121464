import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const Login = () => 
import(/* webpackChunkName: "chlogin" */'../views/Login.vue')
const Main = () => 
import(/* webpackChunkName: "chmain" */'../views/Main.vue')
const UploadedStores = () => 
import(/* webpackChunkName: "chstores" */'../views/UploadedStores.vue')
const SelectUser = () => 
import(/* webpackChunkName: "chselectuser" */'../views/SelectUser.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SelectUser',
    component: SelectUser
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    beforeEnter:(to,from,next) => {
      if(store.getters['auth/authenticated']){
        return next({
          name: 'Main',
        })
      }
      next()
    }
  },
  {
    path: '/Main',
    name: 'Main',
    component: Main,
    beforeEnter:(to,from,next) => {
      if(!store.getters['auth/authenticated']){
        return next({
          name: 'Login',
        })
      }
      next()
    }
  },
  {
    path: '/UploadedStores',
    name: 'UploadedStores',
    component: UploadedStores,
    beforeEnter:(to,from,next) => {
      if(!store.getters['auth/authenticated']){
        return next({
          name: 'Login',
        })
      }
      next()
    }
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
