import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './filters'
import VModal from 'vue-js-modal';
import axios from 'axios'
import i18n from './plugins/i18n';
import Notifications from 'vue-notification'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

require('@/store/subscriber')

axios.defaults.baseURL = "https://backendqrfib.singularity-dm.com/api/"
store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
  })
Vue.config.productionTip = false
Vue.use(VModal, { dialog: true })
Vue.use(Notifications)
Vue.component("v-select", vSelect);

