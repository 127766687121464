import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'en': {
        languageselect:"English",

        selectUserSalebtn:"Sales Representative",
        selectUserAdminbtn:"Administrator",

        navbarRegisterStore:"Add Store",
        navbarAllStore:"Uploaded Stores",
        navbarLogout:"Logout",

        loginTitle:"Sign in as a data entry",
        loginEmail:"Email",
        loginPassword:"Password",
        loginBtn:"Login",
        //errors
        loginEmailError:"Enter Your Correct Email",
        loginUnauthorized:"The Account is not Authorized to Enter This Website",
        loginWrongEmail:"Please write your email correctly",
        loginUnAuth:"Wrong Password Or Email",

        mainLocationAlert:"Allow Location access to proceed",
        mainStoreType:"Store Type",
        mainStoreOwnerName:"Store Owner Name",
        mainStoreName:"Store Name",
        mainPhoneNumber:"Phone Number",
        mainPhoneNumber2:"Second Phone Number (Optional)",
        mainCity:"City",
        mainAllCity:"All Cities",
        mainNone:"Empty",
        mainStatus:"Store Status",
        mainDescriptin:"Description",
        mainDate:"Schedule a date",
        mainAccNum:"Account Number",
        mainRejectionReason:"Reason of Rejection",
        mainIsBankUser:"Have bank account?",



        mainPhoto:"Store Image",
        imageName:"Click Here",
        mainBtn:"Submit",
        updateBtn:"Update",
        cancelBtn:"Cancel",

        //errors
        mainStoreTypeError:"Enter Store Type",
        mainStoreOwnerNameError:"Enter Store Owner Name",
        mainStoreNameError:"Enter Store Name",
        mainPhoneNumberError:"Enter Phone Number",
        mainPhoneNumber2Error:"Second Phone Number (Optional)",
        mainCityError:"Select City",
        mainStatusError:"Select Store Status",
        mainDescriptinError:"Enter Description",
        mainPhotoError:"Store Image",
        alertLocationِError:"An Error Occourd While Tyring to Get Your Location, Please Try Again.",
        alertEnterAllFields:"Enter All Fields",
        alertSeccesful:"Successfully Added",
        alertUpdate:"Updated",
        alertNoChange:"Nothing Changed",

        alertLocationِBrowserError:"Your Broswer Doesn't support location services",
        alertFileType:"Invalid File Type",
        alertPhone:"Invalid Phone Number",
        alertError:"Error Occurred",
        alertErrorSignedOut:"An Error Occourd with your account",

        alertErrorPhone:"Phone Number Already Used",

        createdat:"Created At",
        coordinates:"Coordinates",
        actions:"Actions",
        editBtn:"Edit"

    },
    'ar': {
        languageselect:"العربية",

        selectUserSalebtn:"مختصي الشبكة",
        selectUserAdminbtn:"ادارة الشبكة",

        navbarRegisterStore:"تسجيل متجر",
        navbarAllStore:"المتاجر المرفوعة",
        navbarLogout:"تسجيل الخروج",

        loginTitle:"تسجيل دخول المستخدم",
        loginEmail:"البريد الالكتروني ",
        loginPassword:"الرمز السري",
        loginBtn:"دخول",
        //errors
        loginEmailError:"يرجى كتابة البريد الالكتروني بشكل صحيح",
        loginUnauthorized:"الحساب غير مصرح بالدخول",
        loginWrongEmail:"يرجى كتابة البريد الاللكتروني بشكل صحيح",
        loginUnAuth:"الرمز السري او البريد الالكتروني خاطئ",


        mainLocationAlert:"اقبل الوصول الى الموقع لاكمال عملية التسجيل",
        mainStoreType:"نوع المتجر",
        mainStoreOwnerName:"اسم صاحب المتجر",
        mainStoreName:"اسم المتجر",
        mainPhoneNumber:"رقم الهاتف",
        mainPhoneNumber2:"رقم الهاتف الثاني (اختياري)",
        mainCity:"المحافظة",
        mainAllCity:"جميع المحافظات",
        mainNone:"لا يوجد",
        mainStatus:"حالة المتجر",
        mainDescriptin:"المتطلبات",
        mainDate:"تحديد موعد",
        mainAccNum:"رقم الحساب البنكي",
        mainRejectionReason:"سبب الرفض",
        mainIsBankUser:"يمتلك حساب في البنك؟",

        mainPhoto:"صورة المتجر",
        imageName:"اضغط هنا",

        mainBtn:"ارسال",
        updateBtn:"تحديث",
        cancelBtn:"الغاء",

        //errors
        mainStoreTypeError:"يرجى اختيار نوع المتجر",
        mainStoreOwnerNameError:"يرجى ادخال اسم صاحب المتجر",
        mainStoreNameError:"يرجى ادخال اسم المتجر",
        mainPhoneNumberError:"يرجى ادخال رقم الهاتف الخاص بصاحب المتجر",
        mainCityError:"يرجى اختيار المحافظة",
        mainStatusError:"يرجى اختيار حالة المتجر",
        mainDescriptinError:"يرجى كتابه المتطلبات",
        mainPhotoError:"يرجى اختيار صورة للمتجر",
        alertLocationِError:"حصل خطأ في الحصول على موقع المتجر يرجى المحاولة من جديد",
        alertEnterAllFields:"يرجى ادخال جميع الحقول",
        alertSeccesful:"تم الاضافة بنجاح",
        alertUpdate:"تم التحديث",
        alertLocationِBrowserError:"المتصفح لا يدعم خدمة المواقع",
        alertFileType:"صيغة الملف غير مدعومة",
        alertPhone:"رقم الهاتف غير صحيح",
        alertError:"حصل خطأ في عملية التسجيل",
        alertErrorSignedOut:"حصل خطأ في حسابك يرجى تسجيل الدخول من جديد",
        alertErrorPhone:"رقم الهاتف مستخدم",
        alertNoChange:"لم يتغير شيء",

        createdat:"تاريخ الاضافة",
        coordinates:"الاحداثيات",
        actions:"الاجرائات",
        editBtn:"تعديل",

    }
};
export default  new VueI18n({
    locale: 'ar',
    messages, 
});